import React from "react"

import styled from "styled-components"

import { graphql, useStaticQuery } from "gatsby"

import TrendSquare from "./trend-square"

import tendance1 from "../../tendances/GrandirsansPalir-MaiJuin2022-Scotomalab.pdf"

const ProductsGridContainer = styled.div`
  position: relative;
  display: flex;
  width: 100vw;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;

  > div {
    flex-basis: 100%;
  }

  @media (min-width: 576px) {
    > div {
      flex-basis: 50%;
    }
  }

  @media (min-width: 768px) {
    > div {
      flex-basis: 33.3%;
    }
  }
`

const TrendsGrid = () => {
  const images = useStaticQuery(graphql`
    {
      imageOne: file(relativePath: { eq: "trends/one.jpg" }) {
        id
        publicURL
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      imageTwo: file(relativePath: { eq: "trends/two.jpg" }) {
        id
        publicURL
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      imageThree: file(relativePath: { eq: "trends/three.jpg" }) {
        id
        publicURL
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      imageFour: file(relativePath: { eq: "trends/four.jpg" }) {
        id
        publicURL
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      imageFive: file(relativePath: { eq: "trends/five.jpg" }) {
        id
        publicURL
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      imageSix: file(relativePath: { eq: "trends/six.jpg" }) {
        id
        publicURL
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  let data = [
    {
      cover: images.imageOne,
      title: "Grandir sans pâlir",
      season: "SS 22",
      link: tendance1,
      available: true,
    },
    {
      cover: images.imageTwo,
      title: "L'âme du vaisseau",
      season: "SS 22",
      link: null,
      available: false,
    },
    {
      cover: images.imageThree,
      title: "Trajectoire non-conforme",
      season: "SS 22",
      link: null,
      available: false,
    },
    {
      cover: images.imageFour,
      title: "Périmètre du réel",
      season: "SS 22",
      link: null,
      available: false,
    },
    {
      cover: images.imageFive,
      title: "Nos virées passées",
      season: "SS 22",
      link: null,
      available: false,
    },
    {
      cover: images.imageSix,
      title: "Les reliques de demain",
      season: "SS 22",
      link: null,
      available: false,
    },
  ]
  return (
    <ProductsGridContainer>
      {data.map((item, index) => (
        <TrendSquare data={item} />
      ))}
    </ProductsGridContainer>
  )
}

export default TrendsGrid
