import React, { useState, useEffect } from "react"

import { graphql, useStaticQuery } from "gatsby"

import SEO from "../components/seo"

import Footer from "../components/footer"

import styled from "styled-components"

import TrendsGrid from "../components/TrendsGrid/trends-grid"

import Form from "../components/form"

const Container = styled.div`
  #smiley {
    position: relative;
    display: inline-block;
  }

  pointer-events: all;

  #smiley-container {
    position: relative;
    display: inline-block;
  }
`

const Header = styled.div`
  font-family: "Averta Semibold";
  font-size: 3rem;
  color: white;
  padding: 1rem 1rem;

  @media (min-width: 576px) {
    font-size: 5rem;
    padding: 0;
    padding-left: 3rem;
  }
`

const SubHeader = styled.div`
  font-family: "Averta Semibold";
  font-size: 1.5rem;
  color: white;
  padding: 0 1.3rem;

  @media (min-width: 576px) {
    font-size: 2.5rem;
    padding: 1rem 3.6rem;
  }
`

const Spacer = styled.div`
  height: 8rem;
  width: 100vw;

  @media (min-width: 576px) {
    height: 15rem;
  }
`

const SmallSpacer = styled.div`
  height: 4rem;
  width: 100vw;

  @media (min-width: 576px) {
    height: 6rem;
  }
`

const TrendsPage = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 350)
  }, [])
  // const images = useStaticQuery(graphql`
  //   {
  //     imageOne: file(relativePath: { eq: "trends/one.png" }) {
  //       id
  //       publicURL
  //       childImageSharp {
  //         fluid {
  //           base64
  //           tracedSVG
  //           aspectRatio
  //           src
  //           srcSet
  //           srcWebp
  //           srcSetWebp
  //           sizes
  //           originalImg
  //           originalName
  //           presentationWidth
  //           presentationHeight
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      <SEO title="Trends" />
      <Container>
        <Spacer />
        <Header>Our trend forecasts.</Header>
        <SubHeader>Discover our trend forecasts for the coming year.</SubHeader>
        <SmallSpacer />
        <TrendsGrid />
        <SmallSpacer />
        <SubHeader>Our trend forecast are released bi-monthly.</SubHeader>
        <Form />
        <Footer />
      </Container>
    </>
  )
}

export default TrendsPage
