import React, { useEffect } from "react"
import styled from "styled-components"

import LockIcon from "../../images/lock.svg"

import { TimelineMax, Power2, Power3 } from "gsap"

import Img from "gatsby-image"

const Container = styled.div`

display: flex;
justify-content: center;
align-items: center;

    height: 100vw;
    width: 100vw;

    margin-bottom: 5rem;


  @media(min-width: 576px) {

        height: calc(100vw / 2);
        width: calc(100vw / 2);

  }

  @media(min-width: 768px) {

   height: calc(100vw / 3);
    width: calc(100vw / 3);

`

const Inner = styled.div`
  position: relative;
  margin: 0 auto;
  height: calc(100% - 50px);
  width: calc(100% - 50px);
  background-color: rgb(255, 255, 255, 0.3);
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);

  .gatsby-image-wrapper {
    object-fit: cover;
    height: 100%;
    transition: transform 0.5s;
  }

  .blur {
    filter: blur(20px);
    transform: scale(1.1);
  }

  :hover.available .gatsby-image-wrapper {
    transform: scale(1.1);
    transition: transform 1s;
  }
`

const Blur = styled.div`
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(20px);
  border-radius: 25px;
`

const Header = styled.div`
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: "Averta Semibold";
  width: 100%;
  text-align: center;
  color: white;
  font-size: 7vw;

  @media (min-width: 576px) {
    font-size: 2vw;
  }
`

const SubHeader = styled.div`
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
  font-family: "Averta Semibold";
  color: white;
  font-size: 3.5vw;

  @media (min-width: 576px) {
    font-size: 1.5vw;
  }
`

const Lock = styled.div`
  position: absolute;
  z-index: 2;
  left: 50%;
  bottom: 10%;
  transform: translateX(-50%);
  text-align: center;

  img {
    position: relative;
    height: 30px;
    width: auto;
  }
`

const DownloadButton = styled.div`
  font-family: "Averta Semibold";
  color: white;
  text-align: center;
  padding-top: 0.5rem;

  font-size: 3.5vw;

  @media (min-width: 576px) {
    font-size: 0.8vw;
  }
`

const DownloadArrowContainer = styled.div`
  position: absolute;
  z-index: 2;
  left: 50%;
  bottom: 10%;
  transform: translateX(-50%);
  text-align: center;

  :hover {
    cursor: pointer;
  }

  svg {
    position: relative;
    top: 6px;
  }

  #Rectangle-3-Copy-5 {
    stroke: white;
  }

  #arrow {
    fill: white;
  }
`

const TrendSquare = props => {
  useEffect(() => {
    if (!props.data.available) return
    //Setup Arrow Circle Animation

    let arrowAnimationArray = []

    function arrowAnimationObject(index) {
      this.arrowTl = new TimelineMax()
      this.circleTl = new TimelineMax()
      this.arrowElement = document.querySelectorAll("#arrow")[index]
      this.circleElement = document.querySelectorAll("#circle-download-arrow")[
        index
      ]
      this.isPlaying = false
      this.trigger = triggerArrowAnimation
    }

    function triggerArrowAnimation() {
      if (this.isPlaying === false) {
        this.isPlaying = true
        this.arrowTl
          .fromTo(
            this.arrowElement,
            0.3,
            { x: 0 },
            { x: 35, ease: Power2.easeIn }
          )
          .set(this.arrowElement, { x: -35 })
          .to(this.arrowElement, 1, {
            x: 0,
            opacity: 1,
            ease: Power3.easeOut,
          })
          .call(() => {
            this.isPlaying = false
          })

        this.circleTl
          .to(this.circleElement, 0.5, {
            scale: 1.02,
            opacity: 1,
            ease: Power3.easeInOut,
          })
          .to(this.circleElement, 0.5, {
            opacity: 1,
            scale: 1,
          })
      }
    }

    arrowAnimationArray.push(new arrowAnimationObject(0))
    arrowAnimationArray[0].trigger(0)
    document
      .querySelector("#available-container-download")
      .addEventListener("mouseenter", () => arrowAnimationArray[0].trigger(0))
  }, [])

  return (
    <Container>
      <Inner
        id={props.data.available ? "available-container-download" : ""}
        className={props.data.available ? "available" : ""}
      >
        <a
          href={props.data.link}
          download
          style={props.data.link === null ? { pointerEvents: "none" } : null}
        >
          {props.data.available ? (
            <>
              <DownloadArrowContainer>
                <svg
                  id="circle-download-arrow"
                  width="35px"
                  height="35px"
                  viewBox="0 0 40 40"
                >
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="ZRD_Presse_Desktop"
                      transform="translate(-80.000000, -531.000000)"
                    >
                      <g
                        id="Group-2"
                        transform="translate(100.000000, 551.000000) rotate(90.000000) translate(-100.000000, -551.000000) translate(80.000000, 531.000000)"
                      >
                        <rect
                          id="Rectangle-3-Copy-5"
                          stroke="#000000"
                          x="0.5"
                          y="0.5"
                          width="39"
                          height="39"
                          rx="19.5"
                        ></rect>
                        <path
                          d="M10.8328514,20.7940854 L27.1197224,20.7940854 L24.1122036,23.6418401 C23.7883169,23.9485214 23.7883169,24.4523549 24.1122036,24.7590361 C24.2741469,24.9123768 24.4823597,25 24.7137073,25 C24.9450549,25 25.1532678,24.9123768 25.3152111,24.7590361 L29.757085,20.5531216 C30.0809717,20.2464403 30.0809717,19.7426068 29.757085,19.4359255 L25.2920763,15.230011 C24.9681897,14.9233297 24.4360902,14.9233297 24.1122036,15.230011 C23.7883169,15.5366922 23.7883169,16.0405257 24.1122036,16.347207 L27.1197224,19.1949617 L10.8328514,19.1949617 C10.3701562,19.1949617 10,19.5454545 10,19.9835706 C10,20.4216867 10.3701562,20.7940854 10.8328514,20.7940854 Z"
                          id="arrow"
                          fill="#000000"
                          fillRule="nonzero"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
                <DownloadButton>Download</DownloadButton>
              </DownloadArrowContainer>
            </>
          ) : (
            <Lock>
              <img src={LockIcon} />
              <DownloadButton>Coming Soon</DownloadButton>
            </Lock>
          )}
          {/* {props.data.available ? null : <Blur />} */}
          <Header>{props.data.title}</Header>
          <SubHeader>{props.data.season}</SubHeader>
          {/* <img src={props.data.cover} /> */}
          <Img
            className={props.data.available ? "" : "blur"}
            fluid={props.data.cover.childImageSharp.fluid}
          />
        </a>
      </Inner>
    </Container>
  )
}

export default TrendSquare
