import React, { useEffect } from "react"

import styled from "styled-components"

const FormStyle = styled.div`
  padding-bottom: 7rem;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 7rem;
  box-sizing: border-box;
  width: 100vw;

  input {
    position: relative;
    font-family: "Averta Semibold";
    font-size: 1.5rem;
    color: white;
    font-weight: 100;
    border: 0;
    background: none;
    border-bottom: 2px solid white;
    width: 100%;
    outline: none;
    border-radius: 0;
    height: 40px;
    width: 100%;
    padding: 0;

    @media (min-width: 576px) {
      width: 45vw;
      font-size: 2.5rem;
      height: 60px;
      min-width: 600px;
    }
  }

  @media (min-width: 576px) {
    padding-bottom: 1.5rem;
    padding-right: 1.5rem;
    padding-left: 3.6rem;
    order: 0;
    width: fit-content;
  }

  input::placeholder {
    color: white;
    opacity: 0.3;
  }

  form {
    position: relative;
  }

  button {
    font-family: "Averta Semibold";
    font-size: 1.5rem;
    color: white;
    // line-height: 2.1rem;
    font-weight: 100;
    color: white;
    border: none;
    border-bottom: 0;
    background: none;
    outline: none;
    position: absolute;
    right: 0px;
    padding: 0;
    margin: 0;
    top: 7px;
    cursor: pointer;

    @media (min-width: 576px) {
      right: 0px;
      top: 13px;
      line-height: 2.5rem;
      font-size: 2.5rem;
    }
  }
`

const Confirmation = styled.div`
  font-family: "Averta Semibold";
  font-size: 1.5rem;
  color: white;
  display: none;

  @media (min-width: 576px) {
    line-height: 2.5rem;
    font-size: 2.5rem;
  }
`

const Form = () => {
  useEffect(() => {
    // get the form elements defined in your form HTML above

    var form = document.getElementById("my-form")
    var input = document.getElementById("my-form")
    var button = document.getElementById("my-form-button")
    var confirmation = document.getElementById("confirmation")
    // var status = document.getElementById("my-form-status")

    // Success and Error functions for after the form is submitted

    function success() {
      form.reset()
      form.style.display = "none"
      confirmation.style.display = "block"
      //   status.innerHTML = "Thanks!"
    }

    function error() {
      //   status.innerHTML = "Oops! There was a problem."
    }

    // handle the form submission event

    form.addEventListener("submit", function(ev) {
      ev.preventDefault()
      var data = new FormData(form)
      ajax(form.method, form.action, data, success, error)
    })

    // helper function for sending an AJAX request

    function ajax(method, url, data, success, error) {
      var xhr = new XMLHttpRequest()
      xhr.open(method, url)
      xhr.setRequestHeader("Accept", "application/json")
      xhr.onreadystatechange = function() {
        if (xhr.readyState !== XMLHttpRequest.DONE) return
        if (xhr.status === 200) {
          success(xhr.response, xhr.responseType)
        } else {
          error(xhr.status, xhr.response, xhr.responseType)
        }
      }
      xhr.send(data)
    }
  }, [])

  return (
    <FormStyle>
      <form method="POST" action="https://formspree.io/xoqdvekq" id="my-form">
        <input
          type="email"
          name="email"
          id="input"
          placeholder="Keep me updated."
          required
        />
        <button id="my-form-button" type="submit">
          Submit
        </button>
      </form>
      <Confirmation id="confirmation">Thanks, see you soon! 💌</Confirmation>
    </FormStyle>
  )
}

export default Form
